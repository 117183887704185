import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./critical-event-management@gen.scss";

const GeneratedUseCasesCriticalEventManagement = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty活用例：危機管理｜PagerDuty正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyは、あらゆる重要な問題にどこからでも迅速に対応し解決できる危機管理ソリューションとしてお使いいただけます。ぜひお試しください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyは、あらゆる重要な問題にどこからでも迅速に対応し解決できる危機管理ソリューションとしてお使いいただけます。ぜひお試しください。"
        />

        <meta
          property="og:title"
          content="PagerDuty活用例：危機管理｜PagerDuty正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyは、あらゆる重要な問題にどこからでも迅速に対応し解決できる危機管理ソリューションとしてお使いいただけます。ぜひお試しください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/use-cases/critical-event-management/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/use-cases/critical-event-management/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Solution"
        textGreen="PagerDuty活用例：危機管理"
        overview=""
      />

      <div className="itoperationsWrapper">
        <div className="itoperations-container">
          <div className="mt-0 center-1">
            <h3 className="center-title mb-[40px]">
              御社の危機管理計画は万全ですか？
            </h3>
            <p className="text-center md:px-0">
              政治的、社会的な混乱は、多くの場合、組織を危機的状況に追い込みがちです。組織は従業員の安全を確保し、ビジネスの継続性を維持するために、明確な危機管理プロセスを確立する必要があります。
            </p>
            <p className="text-center md:px-0 mt-[30px]">
              経験豊富な危機管理チームを持つ組織は、既にその能力を強化し、リモートワーク中心の作業モデルへの適応を開始しています。しかし、パンデミック以前にそのようなチームを持たなかった企業は、この役割を担う社員を迅速にトレーニングし、危機が発生した際の対応とコミュニケーションを管理する適切なツールを見つけなければなりません。
            </p>
            <p className="text-center md:px-0 mt-[30px]">
              PagerDutyのリアルタイムオペレーションプラットフォームは、組織が緊急の仕事に優先順位をつけ、効率的な管理を実現することに貢献します。
            </p>
          </div>
          <div className="text-center mt-[100px] px-[25px] md:px-[0px]">
            <h3 className="relative center-title max-w-max">
              危機のまさにそのとき、PagerDutyがお役に立ちます
            </h3>
          </div>
          <div className="py-0 my-0 bg-transparent three-col-blog-card-image-grey mt-[50px] mb-[100px]">
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={72}
                  height={80}
                  viewBox="0 0 72 80"
                  fill="none"
                >
                  <path
                    d="M69.2854 10.7581C70.4743 10.7581 71.4384 9.79406 71.4384 8.60516V7.84859C71.4384 6.65953 70.4743 5.69547 69.2854 5.69547H38.5312V0H33.4687V5.69547H2.71465C1.52559 5.69547 0.561523 6.65953 0.561523 7.84859V8.60516C0.561523 9.79406 1.52559 10.7581 2.71465 10.7581H4.35855V46.8294H2.71465C1.52559 46.8294 0.561523 47.7936 0.561523 48.9825V49.7391C0.561523 50.928 1.52559 51.892 2.71465 51.892H4.35855H33.4687V62.2486L24.7932 71.9189C24.462 71.8323 24.1223 71.7731 23.765 71.7731C21.4932 71.7731 19.6515 73.6147 19.6515 75.8866C19.6515 78.1583 21.4932 80 23.765 80C26.0368 80 27.8784 78.1583 27.8784 75.8866C27.8784 75.3723 27.7734 74.8853 27.6003 74.4317L36.1101 64.9467L44.3703 74.5281C44.2195 74.9558 44.1218 75.4081 44.1218 75.8866C44.1218 78.1583 45.9636 80 48.2353 80C50.507 80 52.3487 78.1583 52.3487 75.8866C52.3487 73.6147 50.507 71.7731 48.2353 71.7731C47.8434 71.7731 47.4715 71.8461 47.1118 71.9486L38.6923 62.1794L38.5959 62.1769L38.6923 62.0694H38.5312V51.892H67.6414H69.2854C70.4743 51.892 71.4384 50.928 71.4384 49.7391V48.9825C71.4384 47.7936 70.4743 46.8294 69.2854 46.8294H67.6414V10.7581H69.2854ZM8.15543 46.8294V10.7581H63.8445V46.8294H8.15543Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M32.0245 22.6003C30.1261 20.31 27.2511 18.8405 24.0462 18.8405C23.9103 18.8405 23.7533 18.8405 23.5816 18.8515C23.5791 18.8515 23.5778 18.8515 23.5741 18.8515C23.5703 18.8515 23.5691 18.8515 23.5631 18.8515V18.8528C18.0827 19.1098 13.7122 23.63 13.7109 29.1758C13.7122 34.8823 18.3409 39.5111 24.0464 39.5123C29.753 39.5111 34.3817 34.8823 34.383 29.1758C34.3842 26.678 33.4919 24.3875 32.0283 22.6039L32.0245 22.6003ZM30.2522 23.4469L24.0945 28.9694V20.7228C26.5244 20.7351 28.7097 21.7869 30.2522 23.4469Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M55.477 19.4731H40.2891V23.2702H55.477V19.4731Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M55.477 27.0672H40.2891V30.8641H55.477V27.0672Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M50.4144 34.6611H40.2891V38.4581H50.4144V34.6611Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="text-[28px] font-bold text-center">
                あらゆる重要な問題に迅速に対応
              </h4>
              <p className="mt-[30px] text-center">
                COVID-19、政情不安、その他の深刻な問題に関連するあらゆる報告が、危機管理チームに届くようになります。従業員が必要なリソースや支援にアクセスできるようにし、影響を受ける可能性のある従業員や経営陣が最新の情報を入手可能にすることができます。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={80}
                  height={78}
                  viewBox="0 0 80 78"
                  fill="none"
                >
                  <path
                    d="M80 26.3801L74.3116 27.1795C69.3336 11.9275 55.0167 0.900754 38.1023 0.898254C17.0564 0.900754 0.00125 17.9548 0 38.9994C0.00125 60.0451 17.0564 77.1005 38.1023 77.1017C39.2228 77.1017 40.3381 77.0536 41.442 76.957L40.6744 68.1192H40.6731C39.8294 68.1915 38.9728 68.2284 38.1023 68.2284C30.0156 68.2258 22.7386 64.9648 17.4333 59.6684C12.137 54.3631 8.87453 47.0847 8.87344 38.9994C8.87469 30.9139 12.137 23.6354 17.4333 18.3315C22.7386 13.0351 30.0156 9.77419 38.1023 9.77154C46.1877 9.77419 53.4661 13.0351 58.77 18.3315C61.62 21.1853 63.8531 24.6253 65.3341 28.4409L60.4741 29.1247L73.0122 47.4451L75.8138 38.9994H76.202C76.202 38.6567 76.1614 38.3242 76.1514 37.9828L80 26.3801Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M62.3379 55.3401C61.8607 56.0483 61.3482 56.7372 60.8076 57.4084L67.7054 62.9878C68.4032 62.125 69.0668 61.233 69.6926 60.3053L62.3406 55.3401H62.3379Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M49.7256 65.8262C48.307 66.4417 46.8287 66.9467 45.3047 67.3337L47.4822 75.9369C49.4756 75.4319 51.4056 74.7695 53.2581 73.965L49.7256 65.8275V65.8262Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M53.8516 63.6234L58.643 71.0935C60.3609 69.9934 61.9827 68.7574 63.4952 67.4035L57.5795 60.7912C56.4159 61.8304 55.1712 62.7796 53.8516 63.6234Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M33.7273 38.4296C35.1332 36.8284 35.9846 35.1648 35.9846 33.0406C35.9846 29.4434 33.4037 26.7 29.1566 26.7C24.8741 26.7 22.3896 29.3787 22.1612 32.4506C22.127 32.6473 22.2285 32.811 22.4238 32.844L26.016 33.4301C26.247 33.4631 26.3752 33.3654 26.3752 33.1689C26.5705 31.4696 27.4893 30.5548 28.9904 30.5548C30.527 30.5548 31.4088 31.6348 31.4088 33.104C31.4088 34.2462 30.9509 35.1939 30.1032 36.1773L22.2932 45.4554C22.1612 45.6192 22.127 45.7156 22.127 45.9123V48.9512C22.127 49.1479 22.2588 49.2773 22.4543 49.2773H35.6571C35.8526 49.2773 35.9846 49.1479 35.9846 48.9512V45.7156C35.9846 45.5215 35.8526 45.3907 35.6571 45.3907H27.8801V45.3248L33.7273 38.4296Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M55.4676 46.043C55.6606 46.043 55.7914 45.9123 55.7914 45.7155V42.4177C55.7914 42.2211 55.6606 42.0891 55.4676 42.0891H53.997L53.8003 41.8923V37.0908C53.8003 36.8955 53.6695 36.7634 53.4729 36.7634H49.684C49.4873 36.7634 49.3553 36.8953 49.3553 37.0908V41.8923L49.1598 42.0891H45.0778V41.9912L51.7397 27.3862C51.8056 27.1895 51.7396 27.0575 51.5137 27.0575H47.3606C47.1334 27.0575 47.0028 27.1234 46.9064 27.3203L40.2395 41.9912L40.1748 42.3845V45.7155C40.1748 45.9122 40.3054 46.043 40.5023 46.043H49.1598L49.3553 46.2395V48.9511C49.3553 49.1478 49.4873 49.2772 49.684 49.2772H53.4729C53.6695 49.2772 53.8003 49.1478 53.8003 48.9511V46.2395L53.997 46.043H55.4676Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="text-[28px] font-bold text-center">
                製造やサプライチェーンの中断を軽減
              </h4>
              <p className="mt-[30px] text-center">
                製造上のギャップやサプライチェーンの混乱に中央のチームが迅速に対応し、デリバリーを維持し、顧客に最新情報を提供できるようになります。
              </p>
            </div>
            <div className="card">
              <div className="icon-section mb-[30px] min-h-[100px]">
                <svg
                  className="w-[80px] aspect-square"
                  xmlns="http://www.w3.org/2000/svg"
                  width={78}
                  height={80}
                  viewBox="0 0 78 80"
                  fill="none"
                >
                  <path
                    d="M30.1395 46.9395L15.6926 34.8822C13.9796 33.4531 11.4605 33.6311 9.95305 35.2578C8.56195 36.9552 8.70867 39.448 10.3221 40.9594L20.5199 50.4913L19.3501 51.7397L9.15477 42.2078C7.5032 40.6649 6.93008 38.4155 7.47977 36.3794C7.75195 35.5969 8.2518 34.6703 8.90883 34.1363V14.0478C8.90883 11.7381 7.03617 9.86548 4.72648 9.86548H4.64523C2.3343 9.86548 0.462891 11.7381 0.462891 14.0478V40.7184C0.462891 44.2911 1.95742 47.6999 4.58117 50.1195L16.5391 61.778C17.2352 62.4205 17.6318 63.3259 17.6318 64.2734V80H35.6238V59.0453C35.7402 54.3842 33.7212 49.9256 30.1395 46.9395Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M73.355 9.86548H73.2725C70.9641 9.86548 69.0916 11.7381 69.0916 14.0478V34.1361C69.7497 34.6702 70.247 35.5966 70.5206 36.3792C71.0702 38.4153 70.4956 40.6649 68.8456 42.2077L58.6489 51.7395L57.4817 50.4911L67.677 40.9592C69.2933 39.4464 69.4384 36.9524 68.0422 35.2538C66.5347 33.6311 64.0183 33.4544 62.308 34.8822L47.8597 46.9395C44.2806 49.9258 42.2614 54.3842 42.3778 59.0455V80H60.3684V64.2733C60.3684 63.3258 60.7636 62.4203 61.4613 61.7778L73.4178 50.1194C76.0442 47.6997 77.5373 44.2909 77.5373 40.7183V14.0478C77.5373 11.7381 75.6647 9.86548 73.355 9.86548Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M67.9688 35.1517C67.9975 35.1844 68.0147 35.221 68.042 35.2536C68.0708 35.2852 68.1061 35.3086 68.1348 35.3414L67.9688 35.1517Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M39.0002 0L21.7188 9.90359L39.0002 19.807L56.2802 9.90359L39.0002 0Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M21.7188 32.4246L37.897 41.696V21.6181L21.7188 12.3453V32.4246Z"
                    fill="#53B54B"
                  />
                  <path
                    d="M56.2802 12.3453L40.1035 21.6181V41.696L56.2802 32.4246V12.3453Z"
                    fill="#53B54B"
                  />
                </svg>
              </div>
              <h4 className="text-[28px] font-bold text-center">
                重要な問題を管理どこからでも管理可能
              </h4>
              <p className="mt-[30px] text-center">
                リモートワーク中心で分散した危機管理チームが、どのようなプラットフォームでも、また移動中でも対応を指揮できるようになるツールを提供します。
              </p>
            </div>
          </div>
          <img
            className="p-0 md:p-0 mb-[60px]"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/use_cases_web_crisis_managment_e7684bd8ac.svg"
            alt="automation"
          />
          <div className="two-col-media-contents gap-[50px] mt-[0px] md:mt-[0px]">
            <div className="text-part">
              <p className="title">
                コミュニケーションチャネルの迅速な構築と役割・責任の分担
              </p>
              <p className="para">
                メールや緊急の電話は危機管理チームに即時に転送されます。オンコールのローテーションを設定し、個々のチームメンバーが燃え尽きてしまうことがないように留意できるようになります。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_7_56b20a7b63.svg"
                alt="automation image"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">受け入れ課題の優先順位付けを簡単に</p>
              <p className="para">
                危機から生じる問題は数多く、その深刻度やビジネスへの影響もさまざまです。従業員、顧客、ビジネス全体への影響を考慮し、問題の優先順位を迅速に決定できます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_8_24d06ccc14.svg?updated_at=2022-10-05T02:21:27.740Z"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="two-col-media-contents gap-[50px] mt-[60px] md:mt-[60px]">
            <div className="text-part">
              <p className="title">
                経営陣やステークホルダーとの効果的なコミュニケーション
              </p>
              <p className="para">
                ビジネスリーダーやその他のステークホルダーに新たな状況を伝えるための、標準的なプロセスを導入できます。標準化されたメールテンプレートとダッシュボードにより、迅速なコミュニケーション、信頼性の向上、パニックの軽減が実現されます。
              </p>
            </div>
            <div className="p-0 media-contents-part">
              <img
                id="fullImage"
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_9_f67dd71026.svg?updated_at=2022-10-05T02:22:07.502Z"
                alt="auto motion iamge 3"
              />
            </div>
          </div>
          <div className="text-center mt-[100px] mb-[40px]">
            <h3 className="relative center-title max-w-max px-[25px] md:px-[0px]">
              現在お使いのツールとの連携
            </h3>
          </div>
          <div className="mt-0 center-2 mb-[80px]">
            <p className="text-center">
              今お使いのツールで動作するため、プロセスを変更する必要はありません。
            </p>
            <p className="text-center mt-[30px]">
              650以上のネイティブな統合機能と、拡張可能なPagerDuty
              APIを使用してワークフローを構築・カスタマイズする機能により、あらゆるツールからデータを統合し、ITインフラへのインサイトを提供します。強化されたEvent
              API
              v2は、全ての受信イベントを共通のフィールドに自動的に正規化します。
            </p>
            <p className="text-center mt-[30px]">
              双方向の拡張機能によりツール切り替えの手間はなく、インラインで問題を修正し、任意のChatOpsツールで運用関連のタスクを自動化可能。現在お使いのツールとよくなじみます。
            </p>
            <img
              className="p-0 mt-[30px] md:mt-[30px] md:mb-[0px] md:p-0 md:max-w-[600px]"
              src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Group_29_ccec4c21f2.svg?updated_at=2022-09-30T07:27:23.567Z"
              alt="automation"
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedUseCasesCriticalEventManagement;
